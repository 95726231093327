import React from "react";
import SEO from "../components/Seo";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { DigitalAmericaContent } from "@organism/DigitalAmericaContent";

import ad_logos from "../assets/image/logos_landing_ad.png";

const DigitalAmerica = ({ location }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t("SEO.DIGITAL_AMERICA.TITLE")}
        description={t("SEO.DIGITAL_AMERICA.DESCRIPTION")}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <DigitalAmericaContent />
      <img src={ad_logos} alt="ad_logos" style={{marginLeft:0}} height={80} loading="lazy"/>
    </>
  );
};

export default DigitalAmerica;
