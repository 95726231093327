import React from "react";
import { DigitalAmericaForm } from "@molecules/DigitalAmericaForm";
import "./styles.scss";

import "../../../assets/font/muli/Muli-Black.ttf";
import "../../../assets/font/muli/Muli-Bold.ttf";
import "../../../assets/font/muli/Muli-Light.ttf";
import "../../../assets/font/muli/Muli-Regular.ttf";

export const DigitalAmericaContent = () => {
  return (
    <main className="digital_america_container">
      <header>
        <h1>América Digital 2022</h1>
        <h2>
          Registrate para vivir una experiencia única junto a C-Levels de la
          transformación digital.
        </h2>
        <ul className="mobile_event_content">
          <li>Conferencias ilimitadas</li>
          <li>Contenido exclusivo</li>
          <li>Workshop con Soledad Onetto</li>
        </ul>
      </header>
      <DigitalAmericaForm />
    </main>
  );
};
