import React from "react";
import { Form, Formik } from "formik";

import { digitalAmericaForm } from "./validations";

import { FieldInput } from "@atoms/FieldInput";
import { Loading } from "@atoms/Loading";
import { digitalAmerica } from "../../../services/sendEmail";

import "./styles.scss";

export const DigitalAmericaForm = () => {
  const initialValues = {
    name: "",
    email: "",
    linkedin: "",
  };

  const handleSubmit = async (data, actions) => {
    try {
      const { status } = await digitalAmerica(data);
      if (status === 200) {
        actions.setStatus({ message: "Formulario enviado", error: false });
      } else {
        actions.setStatus({ message: "Error al enviar el formulario" });
      }
    } catch (error) {
      actions.setStatus({ message: "Error al enviar el formulario" });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    actions.resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={digitalAmericaForm}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form className="form_container">
          {!isSubmitting &&
            fields.map((field, index) => (
              <div key={index} className="da_field_container">
                <FieldInput {...field} />
              </div>
            ))}

          {isSubmitting && (
            <>
              {status ? (
                <div>
                  <p className="status_message">{status.message}</p>
                </div>
              ) : (
                <Loading />
              )}
            </>
          )}
          
          <button
            type="submit"
            className="btnHero"
            disabled={isSubmitting}
          >
            Regístrate
          </button>
        </Form>
      )}
    </Formik>
  );
};

const fields = [
  {
    type: "text",
    label: "Nombre y Apellido:",
    name: "name",
  },
  {
    type: "email",
    label: "Correo:",
    name: "email",
  },
  {
    type: "text",
    label: "Linkedin:",
    name: "linkedin",
  },
];
