import * as yup from "yup";

const requiredField = "Este campo es requerido";
const invalidUrl = "Debe ser una url valida";
const invalidEmail = "Debe ser un email valido";

export const digitalAmericaForm = yup.object().shape({
  name: yup.string().required(requiredField),
  email: yup.string().email(invalidEmail).required(requiredField),
  linkedin: yup.string().url(invalidUrl).required(requiredField),
});
